import * as E from "@react-email/components";
import { json, redirect } from "@remix-run/node";
import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { Form, useActionData, useLoaderData } from "@remix-run/react";
import { z } from "zod";
import { requireAnonymous } from "~/utils/auth-utils/auth.server";
import { prisma } from "~/utils/db.server";
import { sendEmail } from "~/integrations/email.server";
import { conform, useForm } from "@conform-to/react";
import { getFieldsetConstraint, parse } from "@conform-to/zod";
import { EmailSchema } from "~/utils/model-utils/user-validation";
import { prepareVerification } from "./verify";
import { useIsPending } from "~/utils/misc";
import { AuthenticityTokenInput } from "remix-utils/csrf/react";
import { validateCSRF } from "~/utils/csrf.server";
import { Button } from "~/components/ui/Button";
import { getOption } from "~/utils/misc.server";
import type { Prisma } from "@prisma/client";
import { SpamError } from "remix-utils/honeypot/server";
import { honeypot } from "~/utils/honeypot.server";
import { HoneypotInputs } from "remix-utils/honeypot/react";
import { Label } from "~/components/ui/Label";
import { Input } from "~/components/ui/Input";
import CountryWarning from "./country-warning";
import { Card } from "~/components/ui/Card";
import ErrorList from "~/components/ui/ErrorList";

export const onboardingEmailSessionKey = "onboardingToken";

export const meta: MetaFunction = () => {
  return [
    { title: "Sign up for Ranger" },
    {
      property: "og:title",
      content: "Sign up for Ranger",
    },
    {
      name: "description",
      content:
        "Ranger is a budgeting app designed to be as frictionless as possible. Understand what you’re spending, save the money you want, and organize your finances without fear.",
    },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  await requireAnonymous(request);
  const { searchParams } = new URL(request.url);
  console.log(searchParams);
  const bypassCountryNotice =
    searchParams.get("bypassCountryNotice") != null || false;

  const requestCountryCode = request.headers.get("cf-ipcountry") ?? null;
  const isValidCountry =
    requestCountryCode === "US" ||
    requestCountryCode === null ||
    bypassCountryNotice;

  return { isValidCountry };
}

const SignupSchema = z.object({
  email: EmailSchema,
});

export async function action({ request }: ActionFunctionArgs) {
  await requireAnonymous(request);
  const formData = await request.formData();
  let caughtByHoneypot = false;
  // Honeypot check
  try {
    honeypot.check(formData);
  } catch (error) {
    if (error instanceof SpamError) {
      caughtByHoneypot = true;
    } else {
      throw error;
    }
  }

  // 🐨 validate the CSRF token
  await validateCSRF(formData, request.headers);
  const globalInviteOverride = await getOption("global_invite_override");
  const globalInviteOverrideValue =
    globalInviteOverride?.value as Prisma.JsonObject;

  const submission = await parse(formData, {
    // Custom validation logic
    schema: SignupSchema.superRefine(async (data, ctx) => {
      // Honeypot check
      if (caughtByHoneypot) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Invalid sweetener",
        });
        return z.NEVER;
      }

      // Validate that email isn't already taken
      const existingUser = await prisma.user.findUnique({
        where: { email: data.email },
        select: { id: true },
      });
      if (existingUser) {
        ctx.addIssue({
          path: ["email"],
          code: z.ZodIssueCode.custom,
          message: "A user already exists with this email",
        });
        return;
      }

      if (globalInviteOverrideValue.enabled === false) {
        ctx.addIssue({
          path: ["email"],
          code: z.ZodIssueCode.custom,
          message:
            "Sorry, we're not currently accepting any new signups. Please check back in the future.",
        });
      }
    }),
    async: true,
  });

  // Abandon ship if the intent isn't Submit, or if we have any errors
  if (submission.intent !== "submit") {
    return json({ status: "idle", submission } as const);
  }
  if (!submission.value) {
    return json({ status: "error", submission } as const, { status: 400 });
  }

  const { email } = submission.value;
  const { verifyUrl, redirectTo, otp } = await prepareVerification({
    period: 10 * 60,
    request,
    type: "onboarding",
    target: email,
  });

  // Send an email
  const response = await sendEmail({
    to: email,
    subject: `Welcome to Ranger!`,
    react: <SignupEmail onboardingUrl={verifyUrl.toString()} otp={otp} />,
  });
  console.log("🌐 ONE TIME PASSWORD: ", otp);

  if (response.status === "success") {
    return redirect(redirectTo.toString());
  } else {
    submission.error[""] = [response.error.message];
    return json({ status: "error", submission } as const, { status: 500 });
  }
}

export function SignupEmail({
  onboardingUrl,
  otp,
}: {
  onboardingUrl: string;
  otp: string;
}) {
  return (
    <E.Html lang="en" dir="ltr">
      <E.Container>
        <h1>
          <E.Text>Welcome to Ranger!</E.Text>
        </h1>
        <p>
          <E.Text>
            Here's your verification code: <strong>{otp}</strong>
          </E.Text>
        </p>
        <p>
          <E.Text>Or click the link to get started:</E.Text>
        </p>
        <E.Link href={onboardingUrl}>{onboardingUrl}</E.Link>
      </E.Container>
    </E.Html>
  );
}

export default function SignupRoute() {
  const actionData = useActionData<typeof action>();
  const isPending = useIsPending();
  const { isValidCountry } = useLoaderData<typeof loader>();

  const [form, fields] = useForm({
    id: "signup-form",
    constraint: getFieldsetConstraint(SignupSchema),
    lastSubmission: actionData?.submission,
    onValidate({ formData }) {
      const result = parse(formData, { schema: SignupSchema });
      return result;
    },
    shouldRevalidate: "onBlur",
  });

  return (
    <div className="mx-auto flex w-full max-w-md flex-col gap-8 lg:gap-10">
      <div className="flex flex-col gap-3 text-center">
        <p className="text-2xl font-semibold">
          Sign up to start your free trial
        </p>
        <p className="text-base text-muted-foreground">
          Try Ranger free, cancel anytime.
        </p>
      </div>

      <div className="mx-auto w-full max-w-md">
        {isValidCountry && (
          <>
            <Form
              method="POST"
              {...form.props}
              className="mx-auto flex max-w-sm flex-col gap-4 md:gap-6"
            >
              <AuthenticityTokenInput />
              <HoneypotInputs />
              <div className="space-y-2">
                <Label>Email</Label>
                <Input type="email" {...conform.input(fields.email)} />
                {fields.email.errors && (
                  <div id={`${fields.email.id}-error`}>
                    <ErrorList errors={fields.email.errors} />
                  </div>
                )}
              </div>
              <ErrorList errors={form.errors} id={form.errorId} />
              <Button className="w-full" type="submit" disabled={isPending}>
                Sign up with email
              </Button>
            </Form>
            <Card className="mx-auto mt-10 max-w-sm bg-secondary text-sm leading-relaxed text-muted-foreground">
              <p>
                <strong>Notice:</strong> Ranger is only supported in the United
                States. If you want to see support for your country added, let
                us know at{" "}
                <a href="mailto:help@rangerbudget.com">help@rangerbudget.com</a>
                !
              </p>
            </Card>
          </>
        )}
        {!isValidCountry && <CountryWarning />}
      </div>
    </div>
  );
}
