import type { Submission } from "@conform-to/react";
import { conform, useForm } from "@conform-to/react";
import { getFieldsetConstraint, parse } from "@conform-to/zod";
import {
  Form,
  useActionData,
  useLoaderData,
  useSearchParams,
} from "@remix-run/react";
import type { DataFunctionArgs } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import z from "zod";
import { prisma } from "~/utils/db.server";
import { useIsPending } from "~/utils/misc";
import { getDomainUrl, redirectWithToast } from "~/utils/misc.server";
import {
  handleVerification as handleLoginTwoFactorVerification,
  shouldRequestTwoFA,
} from "./login";
import { handleVerification as handleOnboardingVerification } from "./onboarding";
import { handleVerification as handleResetPasswordVerification } from "./reset-password";
import { generateTOTP, verifyTOTP } from "~/utils/totp.server";
import { AuthenticityTokenInput } from "remix-utils/csrf/react";
import { validateCSRF } from "~/utils/csrf.server";
import { twoFAVerificationType } from "../two-factor";
import { Button } from "~/components/ui/Button";
import { requireAuth } from "~/utils/auth-utils/requireAuth.server";
import { Label } from "~/components/ui/Label";
import { Input } from "~/components/ui/Input";
import { SignupEmail } from "./signup";
import { sendEmail } from "~/integrations/email.server";
import ErrorList from "~/components/ui/ErrorList";
export const codeQueryParam = "code";
export const targetQueryParam = "target";
export const typeQueryParam = "type";
export const redirectToQueryParam = "redirectTo";
export const rememberQueryParam = "remember";
const types = ["onboarding", "reset-password", "change-email", "2fa"] as const;
const VerificationTypeSchema = z.enum(types);
export type VerificationTypes = z.infer<typeof VerificationTypeSchema>;

const VerifySchema = z.object({
  [codeQueryParam]: z.string().min(6).max(6),
  [typeQueryParam]: VerificationTypeSchema,
  [targetQueryParam]: z.string(),
  [redirectToQueryParam]: z.string().optional(),
  [rememberQueryParam]: z.boolean().optional().optional(),
});

export async function loader({ request }: DataFunctionArgs) {
  const params = new URL(request.url).searchParams;
  if (!params.has(codeQueryParam)) {
    // we don't want to show an error message on page load if the otp hasn't be
    // prefilled in yet, so we'll send a response with an empty submission.
    return json({
      status: "idle",
      submission: {
        intent: "",
        payload: Object.fromEntries(params),
        error: {},
      },
    } as const);
  }
  return validateRequest(request, params);
}

export function getRedirectToUrl({
  request,
  type,
  target,
  redirectTo,
}: {
  request: Request;
  type: VerificationTypes;
  target: string;
  redirectTo?: string;
}) {
  const redirectToUrl = new URL(`${getDomainUrl(request)}/verify`);
  redirectToUrl.searchParams.set(typeQueryParam, type);
  redirectToUrl.searchParams.set(targetQueryParam, target);
  if (redirectTo) {
    redirectToUrl.searchParams.set(redirectToQueryParam, redirectTo);
  }
  return redirectToUrl;
}

export async function prepareVerification({
  period,
  request,
  type,
  target,
}: {
  period: number;
  request: Request;
  type: VerificationTypes;
  target: string;
}) {
  const verifyUrl = getRedirectToUrl({ request, type, target });
  const redirectTo = new URL(verifyUrl.toString());

  const { otp, ...verificationConfig } = generateTOTP({
    algorithm: "SHA256",
    period,
  });
  const verificationData = {
    type,
    target,
    ...verificationConfig,
    expiresAt: new Date(Date.now() + verificationConfig.period * 1000),
  };
  await prisma.verification.upsert({
    where: { target_type: { target, type } },
    create: verificationData,
    update: verificationData,
  });

  // add the otp to the url we'll email the user.
  verifyUrl.searchParams.set(codeQueryParam, otp);

  return { otp, redirectTo, verifyUrl };
}

export const twoFAVerifyVerificationType = "2fa-verify";

export async function requireRecentVerification(request: Request) {
  const { userId } = await requireAuth(request);
  const shouldReverify = await shouldRequestTwoFA(request);
  if (shouldReverify) {
    const reqUrl = new URL(request.url);
    const redirectUrl = getRedirectToUrl({
      request,
      target: userId,
      type: twoFAVerificationType,
      redirectTo: reqUrl.pathname + reqUrl.search,
    });
    throw await redirectWithToast(
      request,
      redirectUrl.toString(),
      "Please reverify your account before proceeding",
      null,
    );
  }
}

export async function isCodeValid({
  code,
  type,
  target,
}: {
  code: string;
  type: VerificationTypes | typeof twoFAVerifyVerificationType;
  target: string;
}) {
  const verification = await prisma.verification.findUnique({
    where: {
      target_type: { target, type },
      OR: [{ expiresAt: { gt: new Date() } }, { expiresAt: null }],
    },
    select: { algorithm: true, secret: true, period: true },
  });
  if (!verification) return false;
  const result = verifyTOTP({
    otp: code,
    secret: verification.secret,
    algorithm: verification.algorithm,
    period: verification.period,
  });
  if (!result) return false;

  return true;
}

export type VerifyFunctionArgs = {
  request: Request;
  submission: Submission<z.infer<typeof VerifySchema>>;
  body: FormData | URLSearchParams;
};

async function canResendVerification(target: string, type: string) {
  const verification = await prisma.verification.findUnique({
    where: { target_type: { target, type } },
    select: { updatedAt: true },
  });

  if (!verification) return true;

  const timeSinceLastResend = Date.now() - verification.updatedAt.getTime();
  return timeSinceLastResend > 1000 * 60;
}

async function validateRequest(
  request: Request,
  body: URLSearchParams | FormData,
) {
  // Special case for resend intent
  if (body.get("intent") === "resend") {
    const submission = await parse(body, {
      schema: z.object({
        intent: z.literal("resend"),
        [typeQueryParam]: VerificationTypeSchema,
        [targetQueryParam]: z.string(),
      }),
    });

    if (!submission.value) {
      return json({ status: "error", submission } as const, { status: 400 });
    }

    const { type, target } = submission.value;

    // Check rate limit
    const canResend = await canResendVerification(target, type);
    if (!canResend) {
      submission.error[""] = [
        "Please wait a minute before requesting another email",
      ];
      return json({ status: "error", submission } as const, { status: 429 });
    }

    const { verifyUrl, otp } = await prepareVerification({
      period: 10 * 60,
      request,
      type,
      target,
    });

    const response = await sendEmail({
      to: target,
      subject: `Welcome to Ranger!`,
      react: <SignupEmail onboardingUrl={verifyUrl.toString()} otp={otp} />,
    });
    console.log("🌐 ONE TIME PASSWORD: ", otp);

    if (response.status === "success") {
      throw await redirectWithToast(
        request,
        request.url,
        "Verification email re-sent",
        "success",
      );
    } else {
      throw await redirectWithToast(
        request,
        request.url,
        response.error.message,
        "error",
      );
    }
  }

  const submission = await parse(body, {
    schema: () =>
      VerifySchema.superRefine(async (data, ctx) => {
        const codeIsValid = await isCodeValid({
          code: data[codeQueryParam],
          type: data[typeQueryParam],
          target: data[targetQueryParam],
        });
        if (!codeIsValid) {
          ctx.addIssue({
            path: ["code"],
            code: z.ZodIssueCode.custom,
            message: `Invalid code`,
          });
          return;
        }
      }),
    async: true,
  });

  if (submission.intent !== "submit") {
    return json({ status: "idle", submission } as const);
  }
  if (!submission.value) {
    return json({ status: "error", submission } as const, { status: 400 });
  }

  const { value: submissionValue } = submission;

  async function deleteVerification() {
    await prisma.verification.delete({
      where: {
        target_type: {
          type: submissionValue[typeQueryParam],
          target: submissionValue[targetQueryParam],
        },
      },
    });
  }

  switch (submissionValue[typeQueryParam]) {
    case "reset-password": {
      await deleteVerification();
      return handleResetPasswordVerification({ request, body, submission });
    }
    case "onboarding": {
      await deleteVerification();
      return handleOnboardingVerification({ request, body, submission });
    }
    case "2fa": {
      return handleLoginTwoFactorVerification({ request, body, submission });
    }
    case "change-email": {
      // NO SUPPORT YET
      return redirect("/");
    }
  }
}

export async function action({ request }: DataFunctionArgs) {
  const formData = await request.formData();

  // 🐨 validate the CSRF token
  await validateCSRF(formData, request.headers);

  return validateRequest(request, formData);
}

export default function VerifyRoute() {
  const data = useLoaderData<typeof loader>();
  const [searchParams] = useSearchParams();
  const remember = searchParams.get(rememberQueryParam);
  const isPending = useIsPending();
  const actionData = useActionData<typeof action>();
  const type = VerificationTypeSchema.parse(searchParams.get(typeQueryParam));

  const checkEmail = (
    <div className="flex flex-col gap-3 text-center">
      <p className="text-2xl font-semibold">Check your email</p>
      <p className="text-pretty text-base text-muted-foreground">
        Almost there! We've sent you a code to verify your email address.
      </p>
    </div>
  );

  const headings: Record<VerificationTypes, React.ReactNode> = {
    onboarding: checkEmail,
    "reset-password": checkEmail,
    "change-email": checkEmail,
    "2fa": (
      <div className="flex flex-col gap-3 text-center">
        <p className="text-2xl font-semibold">Check your 2FA app</p>
        <p className="text-pretty text-base text-muted-foreground">
          Please enter your 2FA code to verify your identity.
        </p>
      </div>
    ),
  };

  const [form, fields] = useForm({
    id: "verify-form",
    constraint: getFieldsetConstraint(VerifySchema),
    lastSubmission: actionData?.submission ?? data.submission,
    onValidate({ formData }) {
      return parse(formData, { schema: VerifySchema });
    },
    defaultValue: {
      code: searchParams.get(codeQueryParam) ?? "",
      type,
      target: searchParams.get(targetQueryParam) ?? "",
      redirectTo: searchParams.get(redirectToQueryParam) ?? "",
    },
  });

  return (
    <div className="mx-auto flex w-full max-w-md flex-col gap-8 lg:gap-10">
      <div className="flex flex-col gap-3 text-center">{headings[type]}</div>

      <div className="mx-auto flex w-full max-w-md flex-col items-stretch gap-2">
        <Form
          method="POST"
          {...form.props}
          className=" flex flex-col gap-4 md:gap-6"
        >
          <AuthenticityTokenInput />
          <div className="space-y-2">
            <Label>Code</Label>
            <Input {...conform.input(fields[codeQueryParam])} />
            {fields[codeQueryParam].errors && (
              <div id={`${fields[codeQueryParam].id}-error`}>
                <ErrorList errors={fields[codeQueryParam].errors} />
              </div>
            )}
          </div>
          {/* Hidden inputs */}
          <input
            {...conform.input(fields[typeQueryParam], { type: "hidden" })}
          />
          <input
            {...conform.input(fields[targetQueryParam], { type: "hidden" })}
          />
          <input
            {...conform.input(fields[redirectToQueryParam], { type: "hidden" })}
          />
          {remember === "on" && (
            <input name={rememberQueryParam} value="on" type="hidden" />
          )}

          <ErrorList errors={form.errors} id={form.errorId} />
          <Button className="w-full" type="submit" disabled={isPending}>
            Verify
          </Button>
        </Form>
        {type === "onboarding" && (
          <Form method="POST">
            <AuthenticityTokenInput />
            <input type="hidden" name="type" value={type} />
            <input
              type="hidden"
              name="target"
              value={searchParams.get(targetQueryParam) ?? ""}
            />
            <input type="hidden" name="intent" value="resend" />
            <Button
              type="submit"
              variant="outline"
              className="w-full"
              disabled={isPending}
            >
              Resend email
            </Button>
          </Form>
        )}
      </div>
    </div>
  );
}
